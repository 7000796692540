<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
			width="45%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.id }">
							<label class="control-label">Código</label>
							<el-input v-model="form.id" :readonly="recordId !== null"></el-input>
							<small
								class="error--text"
								v-if="errors.id"
								v-text="errors.id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.description }">
							<label class="control-label">Descripción</label>
							<el-input v-model="form.description"></el-input>
							<small
								class="error--text"
								v-if="errors.description"
								v-text="errors.description[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.symbol }">
							<label class="control-label">Símbolo</label>
							<el-input v-model="form.symbol"></el-input>
							<small
								class="error--text"
								v-if="errors.symbol"
								v-text="errors.symbol[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.active }">
							<label class="control-label">Activo</label> <br />
							<el-switch
								v-model="form.active"
								active-text="Si"
								inactive-text="No"
							></el-switch>
							<small
								class="error--text"
								v-if="errors.active"
								v-text="errors.active[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
						<el-button type="primary" native-type="submit" :loading="loading">
							<template v-if="loading">
								Guardando...
							</template>
							<template v-else>
								Guardar
							</template>
						</el-button>
					</v-col>
					<div class="col-md-12"></div>
				</v-row>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'unit-types',
			valid: false,
			errors: {},
			form: {},
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				description: null,
				symbol: null,
				active: true,
			};
		},
		create() {
			this.titleDialog = this.recordId ? 'Editar unidad' : 'Nueva unidad';
			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form = response.data.data;
				});
			}
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
